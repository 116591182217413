import React from 'react';
import { SvgIcon } from "@material-ui/core";

export default function DlabIcon(props) {
  return (
    <SvgIcon style={{width:104,height:52}} viewBox="0 0 200 84" {...props}>
      <svg  xmlns="http://www.w3.org/2000/svg">
        <path fill="#FFFFFF" d="M87.22,65.34a.14.14,0,0,1,.14-.14h1.35a3.54,3.54,0,0,1,1.37.21,1.69,1.69,0,0,1,.77.65,1.86,1.86,0,0,1,.28,1,1.81,1.81,0,0,1-.25.92,1.73,1.73,0,0,1-.64.62,0,0,0,0,0,0,.08,2.51,2.51,0,0,1,.82.44,1.86,1.86,0,0,1,.49.65,1.9,1.9,0,0,1,.18.83,2,2,0,0,1-.67,1.53,2.47,2.47,0,0,1-1.78.63H87.36a.14.14,0,0,1-.14-.14Zm.74.72v2.17a.12.12,0,0,0,.12.12h.31a3.24,3.24,0,0,0,1.15-.15,1.19,1.19,0,0,0,.65-.56,1.29,1.29,0,0,0,.16-.58,1,1,0,0,0-.37-.83,1.78,1.78,0,0,0-1.15-.3h-.75A.12.12,0,0,0,88,66.06Zm0,3.17v2.65a.12.12,0,0,0,.12.12h.81a3.27,3.27,0,0,0,1.21-.16,1.35,1.35,0,0,0,.62-.51,1.23,1.23,0,0,0,.24-.74,1.3,1.3,0,0,0-.33-.88,1.67,1.67,0,0,0-.91-.51,6.69,6.69,0,0,0-1.34-.09h-.3A.12.12,0,0,0,88,69.23Z" />
        <path fill="#FFFFFF" d="M92,65.2h.47a.26.26,0,0,1,.23.13l1.8,2.9a.06.06,0,0,0,.1,0l1.77-2.9a.28.28,0,0,1,.24-.13h.46a.14.14,0,0,1,.12.21L95,69.08a.28.28,0,0,0,0,.12v3.4a.14.14,0,0,1-.14.14h-.46a.14.14,0,0,1-.14-.14V69.2a.17.17,0,0,0,0-.12l-2.26-3.67A.14.14,0,0,1,92,65.2Z" />
        <path fill="#FFFFFF" d="M110.5,65.2h4a.14.14,0,0,1,.14.14v.45a.14.14,0,0,1-.14.14h-3.3a.13.13,0,0,0-.13.13v2.11a.13.13,0,0,0,.13.13h3.27a.14.14,0,0,1,.14.14v.46a.14.14,0,0,1-.14.14h-3.27a.12.12,0,0,0-.13.12v2.72a.12.12,0,0,0,.13.12h3.27a.14.14,0,0,1,.14.14v.46a.14.14,0,0,1-.14.14h-4a.14.14,0,0,1-.14-.14V65.34A.14.14,0,0,1,110.5,65.2Z" />
        <path fill="#FFFFFF" d="M121.9,65.2h-.5a.14.14,0,0,0-.14.14v5.42s-.07.08-.1,0l-4.81-5.52a.14.14,0,0,0-.24.1V72.6a.14.14,0,0,0,.14.14h.5a.14.14,0,0,0,.14-.14V67.17a0,0,0,0,1,.09,0l4.81,5.51h0a.13.13,0,0,0,.23-.08V65.34A.14.14,0,0,0,121.9,65.2Z" />
        <path fill="#FFFFFF" d="M123.21,65.79v-.45a.14.14,0,0,1,.14-.14h4.33a.14.14,0,0,1,.14.14v.45a.14.14,0,0,1-.14.14H126a.13.13,0,0,0-.12.13V72.6a.14.14,0,0,1-.14.14h-.49a.14.14,0,0,1-.14-.14V66.06a.12.12,0,0,0-.12-.13h-1.66A.14.14,0,0,1,123.21,65.79Z" />
        <path fill="#FFFFFF" d="M147.57,65.79v-.45a.14.14,0,0,1,.14-.14H152a.14.14,0,0,1,.14.14v.45a.14.14,0,0,1-.14.14h-1.66a.13.13,0,0,0-.12.13V72.6a.14.14,0,0,1-.14.14h-.49a.14.14,0,0,1-.14-.14V66.06a.13.13,0,0,0-.12-.13h-1.66A.14.14,0,0,1,147.57,65.79Z" />
        <path fill="#FFFFFF" d="M130.89,65.22l-3.52,7.41a.08.08,0,0,0,.07.11h.61a.18.18,0,0,0,.16-.1l1.08-2.29a.15.15,0,0,1,.14-.09h3.06a.15.15,0,0,1,.14.09l1.08,2.25a.23.23,0,0,0,.22.14h.53a.08.08,0,0,0,.08-.11l-3.48-7.41A.09.09,0,0,0,130.89,65.22Zm-1.12,4.11,1.1-2.32a.11.11,0,0,1,.2,0l1.1,2.32a.15.15,0,0,1-.13.2H129.9A.15.15,0,0,1,129.77,69.33Z" />
        <path fill="#FFFFFF" d="M135.9,65.2h.47a.14.14,0,0,1,.14.14V72a.07.07,0,0,0,.07.06h2.69a.14.14,0,0,1,.14.14v.45a.14.14,0,0,1-.14.14H135.9a.14.14,0,0,1-.14-.14V65.34A.14.14,0,0,1,135.9,65.2Z" />
        <path fill="#FFFFFF" d="M140.85,65.2h.47a.14.14,0,0,1,.14.14V72.6a.14.14,0,0,1-.14.14h-.47a.14.14,0,0,1-.14-.14V65.34A.14.14,0,0,1,140.85,65.2Z" />
        <path fill="#FFFFFF" d="M108.58,65.21h-.47a.15.15,0,0,0-.14.11L105.11,71a.08.08,0,0,1-.14,0l-2.9-5.73a.13.13,0,0,0-.13-.09h-.47a.14.14,0,0,0-.14.14v7.26a.14.14,0,0,0,.14.14h.47a.14.14,0,0,0,.14-.14V66.8L105,72.4a.07.07,0,0,0,.13,0L108,66.82v5.79a.14.14,0,0,0,.14.14h.47a.14.14,0,0,0,.14-.14V65.35A.14.14,0,0,0,108.58,65.21Z" />
        <path fill="#FFFFFF" d="M142.78,71.24l.32-.19a.19.19,0,0,1,.26.06c.43.73.92,1.09,1.47,1.09a1.47,1.47,0,0,0,.71-.18,1.31,1.31,0,0,0,.51-.48,1.15,1.15,0,0,0,.17-.63,1.27,1.27,0,0,0-.25-.74,6.31,6.31,0,0,0-1.3-1.21,6.6,6.6,0,0,1-1.17-1,1.87,1.87,0,0,1-.4-1.15,1.73,1.73,0,0,1,.24-.9,1.57,1.57,0,0,1,.66-.64,1.89,1.89,0,0,1,.93-.23,1.94,1.94,0,0,1,1,.26,3.23,3.23,0,0,1,.87.82.19.19,0,0,1,0,.26l-.33.25a.2.2,0,0,1-.26,0,2.39,2.39,0,0,0-.61-.6,1.25,1.25,0,0,0-.65-.18,1,1,0,0,0-.75.27.92.92,0,0,0-.29.68,1.12,1.12,0,0,0,.1.48,1.74,1.74,0,0,0,.38.5c.1.1.42.35,1,.76a5.62,5.62,0,0,1,1.35,1.29A2.06,2.06,0,0,1,147,70.9a1.92,1.92,0,0,1-.62,1.42,2.08,2.08,0,0,1-1.52.61,2.21,2.21,0,0,1-1.25-.37,3.26,3.26,0,0,1-.94-1.07A.18.18,0,0,1,142.78,71.24Z" />
        <path fill="#FFFFFF" d="M155.12,72.56V65.37a.17.17,0,0,1,.17-.17h1.39a8.06,8.06,0,0,1,2.46.27,3.31,3.31,0,0,1,1.72,1.33,4.06,4.06,0,0,1,.61,2.26,4,4,0,0,1-.49,2,3.11,3.11,0,0,1-1.27,1.27,4.82,4.82,0,0,1-2.19.41h-2.23A.18.18,0,0,1,155.12,72.56Zm.72-.69A.16.16,0,0,0,156,72h.71a8,8,0,0,0,2.17-.2,2.45,2.45,0,0,0,1.34-1,3,3,0,0,0,.49-1.76,3.26,3.26,0,0,0-.53-1.87,2.62,2.62,0,0,0-1.47-1.06,9,9,0,0,0-2.33-.22H156a.16.16,0,0,0-.16.16Z" />
        <path fill="#FFFFFF" d="M168,67.34v5.22a.18.18,0,0,1-.17.18h-.36a.18.18,0,0,1-.18-.18v-.68s0,0-.06,0a3.1,3.1,0,0,1-1,.76,2.77,2.77,0,0,1-1.23.27,2.69,2.69,0,0,1-2-.86,2.83,2.83,0,0,1-.83-1.83,3,3,0,0,1,1-2.47,2.72,2.72,0,0,1,1.87-.7,2.59,2.59,0,0,1,1.25.29,2.71,2.71,0,0,1,.91.79,0,0,0,0,0,.06,0v-.74a.18.18,0,0,1,.18-.18h.36A.18.18,0,0,1,168,67.34Zm-2.87.37A2.18,2.18,0,0,0,164,68a2.23,2.23,0,0,0-.81.83,2.28,2.28,0,0,0-.3,1.13,2.24,2.24,0,0,0,.3,1.12,2.18,2.18,0,0,0,.81.84,2.14,2.14,0,0,0,1.1.3,2.2,2.2,0,0,0,1.11-.3,2,2,0,0,0,.82-.8,2.29,2.29,0,0,0,.28-1.14,2.19,2.19,0,0,0-.64-1.62A2.12,2.12,0,0,0,165.11,67.71Z" />
        <path fill="#FFFFFF" d="M169.74,64.86a.56.56,0,0,1,.42.18.54.54,0,0,1,.17.42.53.53,0,0,1-.17.41.56.56,0,0,1-.42.18.62.62,0,0,1-.59-.59.54.54,0,0,1,.18-.42A.54.54,0,0,1,169.74,64.86Zm-.18,2.3h.37a.18.18,0,0,1,.17.18v5.22a.18.18,0,0,1-.17.18h-.37a.17.17,0,0,1-.17-.18V67.34A.17.17,0,0,1,169.56,67.16Z" />
        <path fill="#FFFFFF" d="M182.44,67a2.75,2.75,0,0,1,2.13.93,2.88,2.88,0,0,1,.77,2,2.91,2.91,0,0,1-.81,2,3,3,0,0,1-4.19,0,2.88,2.88,0,0,1-.82-2,2.93,2.93,0,0,1,.77-2A2.79,2.79,0,0,1,182.44,67Zm0,.7a2.09,2.09,0,0,0-1.54.66,2.25,2.25,0,0,0-.64,1.6,2.35,2.35,0,0,0,.29,1.13,2.08,2.08,0,0,0,.8.81,2.28,2.28,0,0,0,2.19,0,2.05,2.05,0,0,0,.79-.81,2.25,2.25,0,0,0,.3-1.13,2.22,2.22,0,0,0-.65-1.6A2.07,2.07,0,0,0,182.43,67.72Z" />
        <path fill="#FFFFFF" d="M175.53,69.06a.17.17,0,0,0-.17.17v.35a.18.18,0,0,0,.17.18h2.56a3.08,3.08,0,0,1-.62,1.27,3.14,3.14,0,0,1-3.75.89,2.88,2.88,0,0,1-1.56-1.6A3.14,3.14,0,0,1,175,65.94a3.11,3.11,0,0,1,2.43,1.16l.51-.5a3.87,3.87,0,0,0-4.38-1.11,3.64,3.64,0,0,0-2.14,2.15,3.85,3.85,0,0,0,6.52,3.9,3.92,3.92,0,0,0,.84-1.78c0-.11,0-.21,0-.32a.49.49,0,0,0,0-.12.24.24,0,0,0-.24-.26Z" />
        <path fill="#FFFFFF" d="M161.75,39h-3.14a.75.75,0,0,0-.74.75v.43a9.24,9.24,0,0,0-5.31-1.6,8.91,8.91,0,0,0-6.68,2.87,10.31,10.31,0,0,0,0,13.67A9,9,0,0,0,152.58,58a9,9,0,0,0,5.29-1.62v.32a.74.74,0,0,0,.74.74h3.14a.75.75,0,0,0,.75-.74v-17A.76.76,0,0,0,161.75,39ZM153,53.68a4.67,4.67,0,0,1-3.56-1.54A5.55,5.55,0,0,1,148,48.2a5.5,5.5,0,0,1,1.42-3.89,4.59,4.59,0,0,1,2.74-1.45l.39,0a4.82,4.82,0,0,1,3.63,1.49,5.4,5.4,0,0,1,1.44,3.9,5.54,5.54,0,0,1-1.44,4,4.63,4.63,0,0,1-2.77,1.45Z" />
        <path fill="#FFFFFF" d="M182.53,41.37a9.12,9.12,0,0,0-6.71-2.8,8.91,8.91,0,0,0-5.2,1.59V27.82a.75.75,0,0,0-.75-.74h-3.11a.74.74,0,0,0-.74.74V56.7a.74.74,0,0,0,.74.74h3.11a.75.75,0,0,0,.75-.74v-.32A8.94,8.94,0,0,0,175.83,58a9,9,0,0,0,6.72-2.85,9.81,9.81,0,0,0,2.61-6.92A9.67,9.67,0,0,0,182.53,41.37Zm-1.64,6.86a5.54,5.54,0,0,1-1.44,4,4.78,4.78,0,0,1-3.62,1.52,4.69,4.69,0,0,1-3.55-1.54,5.51,5.51,0,0,1-1.45-3.94,5.45,5.45,0,0,1,1.42-3.89,4.7,4.7,0,0,1,3.57-1.52,4.85,4.85,0,0,1,3.63,1.49A5.4,5.4,0,0,1,180.89,48.23Z" />
        <path fill="#FFFFFF" d="M141.46,52.88H131a.82.82,0,0,1-.81-.82V27.81a.75.75,0,0,0-.75-.74h-3.11a.74.74,0,0,0-.74.74V56.05A1.43,1.43,0,0,0,127,57.47h14.49a.74.74,0,0,0,.74-.74V53.62A.74.74,0,0,0,141.46,52.88Z" />
        <path fill="#FFFFFF" d="M96.21,27.11H88.66a1.7,1.7,0,0,0-1.7,1.7v27a1.7,1.7,0,0,0,1.7,1.7h7.83c9,0,14.52-5.25,14.52-15.3S105.51,27.11,96.21,27.11Zm-.35,26H92.42a.82.82,0,0,1-.82-.82v-20a.82.82,0,0,1,.82-.82h3.44c6.11,0,10.57,3.23,10.57,10.7S102,53.11,95.86,53.11Z" />
        <rect fill="#FFFFFF" x="113.98" y="40.24" width="8.56" height="4.6" rx="0.74" />
        <path fill="#FFFFFF" d="M32.72,39.29a7.74,7.74,0,0,1-7.09.61v3.29l7,4.06,7-4.06V37.66A8.66,8.66,0,0,0,32.72,39.29ZM34,44.53a.82.82,0,0,1-.82-.81.82.82,0,1,1,1.63,0A.81.81,0,0,1,34,44.53Zm2.28-2.33a1.42,1.42,0,1,1,1.42-1.42A1.42,1.42,0,0,1,36.23,42.2Z" />
        <path fill="#FFFFFF" d="M40.23,50.34H19.54a.72.72,0,1,0,0,1.43H40.23a4,4,0,1,1,0,7.94H20.1a5.45,5.45,0,0,0,0,10.89H34.78V69.19H20.1a4,4,0,0,1,0-8H40.23a5.4,5.4,0,1,0,0-10.8Z" />
        <path fill="#FFFFFF" d="M40.19,53.21H21.8a6,6,0,0,1,0,5.07H40.19a2.54,2.54,0,1,0,0-5.07Z" />
        <path fill="#FFFFFF" d="M72.76,54.6a51.48,51.48,0,0,1-2.55-6.71c-.4-1.21-.57-1.71-.68-2a43,43,0,0,0-3.59-8.32,25.46,25.46,0,0,1-.67-3.46,13.08,13.08,0,0,0-1.82-5.87,14.69,14.69,0,0,0-1.58-5.72c-.39-.63-.8-.26-1,0a21.79,21.79,0,0,0-1.16,3l-.19-.07A17.52,17.52,0,0,0,56.08,21a1.54,1.54,0,0,0-1.51-.41,1.44,1.44,0,0,0-1,1c-.23,1-.61,3.41-.78,4.63l-.35.08a4.3,4.3,0,0,0-1.66.62,5.65,5.65,0,0,0-1.88,2.48,4,4,0,0,1-.63,1.07,1.85,1.85,0,0,1-.25.16,4.26,4.26,0,0,0-2.38,3.51c-.12,2.26,1.84,4.39,3.32,5.8l.31.28a4.94,4.94,0,0,1,.65.62,6.05,6.05,0,0,1,.89,1.72,32.72,32.72,0,0,0-1.67,12.72c-2.08,2.51-4.32,6.8-3.76,13.4a.83.83,0,1,0,1.66-.14c-.54-6.27,1.65-10.23,3.58-12.45a.8.8,0,0,0,.2-.63A31.18,31.18,0,0,1,52.44,43c.22-.66,0-1.43-1.07-3.06a4.89,4.89,0,0,0-1-1l-.25-.22c-.72-.69-2.91-2.76-2.81-4.51A2.6,2.6,0,0,1,48.92,32a3.6,3.6,0,0,0,.54-.38,5.45,5.45,0,0,0,1-1.55,4.3,4.3,0,0,1,1.27-1.8,2.73,2.73,0,0,1,1.11-.4A4.78,4.78,0,0,0,54,27.53a.83.83,0,0,0,.45-.65c.1-.82.47-3.25.72-4.52a16.29,16.29,0,0,1,3.11,4.21.78.78,0,0,0,.49.42c4,1.28,4.4,4,4.9,7.4a25.31,25.31,0,0,0,.76,3.84,58.5,58.5,0,0,1,3.53,8.11,1,1,0,0,0,.08.24c.08.16.36,1,.64,1.84a48.41,48.41,0,0,0,2.71,7C76.7,64.11,76,71,69.24,75.41c-6,3.87-12,3.17-21.35-2.51-5.47-3.34-16.65-7.43-30,2.7a.83.83,0,1,0,1,1.32C24,73.05,29.22,71,34.42,70.84A22.5,22.5,0,0,1,47,74.32c4.86,3,8.77,4.57,12.28,5a15.47,15.47,0,0,0,2.13.15,15.79,15.79,0,0,0,8.7-2.7C74.49,74,80.61,67.28,72.76,54.6Z" />
        <path fill="#FFFFFF" d="M20.67,67.76h23A20.68,20.68,0,0,1,44,62.57H20.67a2.6,2.6,0,0,0,0,5.19Z" />
        <path fill="#FFFFFF" d="M36.33,28.84v-3.3h1.1a.83.83,0,0,0,.83-.83h0a.83.83,0,0,0-.83-.83H35.2a.53.53,0,0,0-.53.53v5.52a.85.85,0,0,0,.42.74l6.16,3.55a1.1,1.1,0,0,1,.54.94v9.29a1.09,1.09,0,0,1-.54.94L33.2,50a1.09,1.09,0,0,1-1.08,0l-8-4.65a1.07,1.07,0,0,1-.54-.94V35.16a1.08,1.08,0,0,1,.54-.94l6.15-3.55a.84.84,0,0,0,.43-.74V24.41a.53.53,0,0,0-.54-.53H27.89a.83.83,0,0,0-.83.83h0a.83.83,0,0,0,.83.83H29v3.3a1.1,1.1,0,0,1-.54.94l-6.14,3.54a.89.89,0,0,0-.44.77V45.52a.89.89,0,0,0,.44.77L30.45,51a4.4,4.4,0,0,0,4.41,0L43,46.29a.89.89,0,0,0,.44-.77V34.09a.89.89,0,0,0-.44-.77l-6.14-3.54A1.1,1.1,0,0,1,36.33,28.84Z" />
      </svg>

    </SvgIcon>
  )
}



