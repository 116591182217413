import { makeStyles } from "@material-ui/core/styles";
import { DlabColor } from "./DlabColor";

/**
 * 共通スタイルオブジェクト
 */
export const CommonStyles = makeStyles((theme) => ({
  bgContentsColor: {
    backgroundColor: theme.palette.background.paper,
  },
  bgHomeTopColor: {
    backgroundColor: DlabColor.DARK.BK_05,
  },
  contentsMaxWidth: {
    [theme.breakpoints.only("xs")]: {
      maxWidth: 343,
    },
    [theme.breakpoints.only("sm")]: {
      maxWidth: 824,
    },
    [theme.breakpoints.only("md")]: {
      maxWidth: 1080,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 1480,
    },
    margin: "auto",
  },
  mainContents: {
    [theme.breakpoints.only("xs")]: {
      padding: 0,
      paddingTop: 16,
    },
    padding: "32px 16px",
  },
  /** iconデフォルトサイズ */
  iconDefault: {
    width: 20,
    height: 20,
  },
  /** メインコンテンツルート要素 */
  mainContentRoot: {
    paddingTop: 20,
    paddingBottom: 20,
    minHeight: "calc(100vh - 200px)",
  },
  /** メインコンテンツ領域 */
  mainWidth: {
    width: "100%",
    maxWidth: 956,
  },
  /** 中央寄せメインコンテナ */
  mainContainer: {
    marginTop: 24,
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 8px",
  },
  /** ヘッダー AppBar */
  appBar: {
    height: 60,
    backgroundColor: "#191f29",
  },
  /** ヘッダー ToolBar */
  toolbar: {
    flexWrap: "wrap",
    minHeight: 64,
  },
  /** ホーム 動画コンテンツ幅 */
  videoContents: {
    display: "flex",
    justifyContent: "space-between",
    margin: "auto",
    // [theme.breakpoints.only('xs')]: {
    //   maxWidth: 343,
    // }
  },
  /** サイドメニュー セパレーター */
  sideMenuDevider: {
    marginRight: 10,
    marginLeft: 10,
  },
  /** サイドメニュー タイトル */
  sideMenuListItemTitle: {
    paddingLeft: 0,
  },
  /** サイドメニュー リストアイコン領域 */
  sideMenuListItemIcon: {
    marginLeft: 10,
    minWidth: 40,
  },
  /** サイドメニュー 非選択時色 */
  sideMenuDisableList: {
    color: DlabColor.DARK.TEXT_LISTGR,
  },
  /** サイドメニュー 非選択時色 */
  sideMenuEnableList: {
    color: DlabColor.DARK.TEXT_WT,
  },
  /** サイドメニュー サブタイトル */
  sideMenuListItemSubTitle: {
    paddingLeft: 20,
  },
  /** サイドメニュー リンクテキスト */
  linkText: {
    color: DlabColor.DARK.TEXT_LISTGR,
    fontFamily: "Noto Sans JP",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 18,
    lineHeight: "20px",
  },
  /** サイドメニュー 下空白 */
  sideMenuSpace: {
    marginBottom: 20,
  },
  /** サイドメニューがない画面のルート */
  noSideMenuRoot: {
    maxWidth: 1676 + 64,
    margin: "auto",
    padding: 0,
    paddingLeft: 32,
    paddingRight: 32,
  },
  /** ページ内リンクテキスト(青) */
  blueLinkText: {
    color: DlabColor.DARK.TEXT_BR,
    fontFamily: "Noto Sans JP",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
  },
  /** 検索画面 - メイン領域 */
  searchMainWidth: {
    width: "100%",
    paddingRight: 15,
    paddingBottom: 10,
  },
  /** Btn_32 ボタン */
  btn32Blue: {
    width: 142,
    heigth: 32,
    backgroundColor: DlabColor.DARK.BTN_BL,
  },
  /** Btnマージン */
  btnMergin: {
    marginRight: 8,
  },
  /** ダイアログタイトル */
  dialogTitle: {
    marginTop: 4,
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 18,
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      fontWeight: 700,
      fontStyle: "normal",
      fontSize: 14,
      lineHeight: "20px",
    },
  },
  /** ダイアログラベル領域 */
  dialogLabel: {
    marginTop: 20,
    marginBottom: 10,
  },
  /** ダイアログボタン領域 */
  dialogButtonArea: {
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
  },
  /** ダイアログボタン */
  dialogButton: {
    width: 198,
    height: 40,
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
  },
  /** ダイアログ説明領域 */
  dialogDescriptionArea: {
    color: DlabColor.DARK.TEXT_WT,
    textAlign: "center",
    marginTop: 40,
    marginBottom: 40,
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 20,
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: 14,
      lineHeight: "14px",
    },
  },
  /** オーディオブック - アバター */
  audiobookAvatar: {
    backgroundColor: "#C4C4C4",
    color: DlabColor.DARK.TEXT_WT,
  },
  /** フォントサイズ一覧 */
  text_h1: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 24,
    lineHeight: "24px",
  },
  text_h1_b: {
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 24,
    lineHeight: "24px",
  },
  text_h2: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 18,
    lineHeight: "20px",
  },
  text_h2_b: {
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 18,
    lineHeight: "20px",
  },
  text_h3: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: "24px",
  },
  text_h3_b: {
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: "24px",
  },
  text_h4: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 14,
    lineHeight: "20px",
  },
  text_h4_b: {
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 14,
    lineHeight: "20px",
  },
  text_h5: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 12,
    lineHeight: "18px",
  },
  text_h5_b: {
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 12,
    lineHeight: "18px",
  },
  text_thumbnail: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 10,
    lineHeight: "10px",
    overflow: "hidden",
    display: "-webkit-Box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    textAlign: "left",
    margin: 1,
  },
  text_chapter_button: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 10,
    lineHeight: "10px",
  },
  btn_bl: {
    backgroundColor: DlabColor.DARK.BTN_BL,
  },
  btn_bk: {
    backgroundColor: DlabColor.DARK.BTN_BK,
  },
  btn_rounded: {
    borderRadius: 32,
  },
  /** フォントカラー一覧 */
  text_wt: {
    color: DlabColor.DARK.TEXT_WT,
  },
  text_gr: {
    color: DlabColor.DARK.TEXT_GR,
  },
  text_listgr: {
    color: DlabColor.DARK.TEXT_LISTGR,
  },
  text_lightgr: {
    color: DlabColor.DARK.TEXT_LIGHTGR,
  },
  text_br: {
    color: DlabColor.DARK.TEXT_BR,
  },
  text_yw: {
    color: DlabColor.DARK.TEXT_YW,
  },
  /** 個別カスタムフォント */
  titleFont: {
    fontSize: 16,
    lineHeight: "24px",
    [theme.breakpoints.only("sm")]: {
      fontSize: 18,
      lineHeight: "20px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 20,
      lineHeight: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 24,
      lineHeight: "20px",
    },
    fontWeight: 700,
  },
  pageTitleFont: {
    fontSize: 18,
    lineHeight: "20px",
    [theme.breakpoints.up("lg")]: {
      fontSize: 24,
      lineHeight: "24px",
    },
    fontWeight: 700,
  },
  /** Contents SubTitle コンポーネント - フォント設定 */
  contentSubTitleFont: {
    fontSize: 14,
    fontWeight: 700,
  },
  myPageBackground: {
    paddingLeft: 100,
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.only("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    backgroundColor: DlabColor.DARK.BK_03,
  },
  /** 動画再生 タイトル */
  playVideoTitleFont: {
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 24,
    lineHeight: "24px",
    paddingTop: 10,
    paddingBottom: 6,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      lineHeight: "20px",
    },
  },
  /** 動画再生 投稿日付テキスト */
  playVideoCreateDateFont: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 14,
    lineHeight: "20px",
    color: DlabColor.DARK.TEXT_LISTGR,
  },
  darkBlackButton: {
    minWidth: 183,
    padding: "10px 16px",
    backgroundColor: DlabColor.DARK.BG_COLOR_MAIN,
    //border: `1px solid ${DlabColor.DARK.BK_05}`,
    color: "white",
    boxShadow: "none",
  },
  areaRoot: {
    paddingTop: 0,
    paddingLeft: 32,
    paddingRight: 32,
    paddingBottom: 0,
  },
  areaTitle: {
    [theme.breakpoints.only("xs")]: {
      paddingTop: 10,
      paddingBottom: 10,
      boxShadow: `inset 0px -2px 0px ${DlabColor.DARK.TEXT_WT}`,
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 12,
      paddingBottom: 12,
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 15,
      paddingBottom: 15,
    },
  },
  areaVideoContainer: {
    paddingTop: 30,
    paddingLeft: 66,
    paddingRight: 66,
    [theme.breakpoints.only("xs")]: {
      paddingTop: 24,
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.only("sm")]: {
      paddingTop: 18,
      paddingLeft: 12,
      paddingRight: 12,
    },
    [theme.breakpoints.only("md")]: {
      paddingLeft: 36,
      paddingRight: 36,
    },
  },
  linkTextColor: {
    color: DlabColor.DARK.TEXT_BL,
  },
  linkTextWrap: {
    wordBreak: "break-all",
  },
}));
