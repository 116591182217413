import React from 'react';
import { SvgIcon } from "@material-ui/core";

export default function YoutubeIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M93.2315 27.9135C92.719 26.0118 91.7173 24.2776 90.3262 22.8833C88.9351 21.489 87.203 20.4834 85.3025 19.9665C78.2555 18.0315 50.063 18 50.063 18C50.063 18 21.875 17.9685 14.8235 19.818C12.9242 20.3587 11.1957 21.3785 9.80405 22.7796C8.4124 24.1807 7.40429 25.9161 6.87651 27.819C5.01801 34.866 5.00001 49.482 5.00001 49.482C5.00001 49.482 4.98201 64.17 6.82701 71.145C7.86201 75.0015 10.8995 78.048 14.7605 79.0875C21.8795 81.0225 49.9955 81.054 49.9955 81.054C49.9955 81.054 78.188 81.0855 85.235 79.2405C87.1362 78.7246 88.8699 77.7213 90.2645 76.3298C91.659 74.9384 92.6662 73.2071 93.1865 71.307C95.0495 64.2645 95.063 49.653 95.063 49.653C95.063 49.653 95.153 34.9605 93.2315 27.9135ZM41.045 63.0225L41.0675 36.0225L64.499 49.545L41.045 63.0225Z" />
      </svg>
    </SvgIcon>
  )
}