import React from 'react';
import { SvgIcon } from "@material-ui/core";

export default function TwitterIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5002 1.19995C5.64003 1.19995 1.7002 5.13978 1.7002 9.99995C1.7002 14.8601 5.64003 18.7999 10.5002 18.7999C15.3604 18.7999 19.3002 14.8601 19.3002 9.99995C19.3002 5.13978 15.3604 1.19995 10.5002 1.19995ZM14.0798 8.40862C14.0834 8.48378 14.0844 8.55895 14.0844 8.63228C14.0844 10.924 12.3418 13.5649 9.15361 13.5649C8.21155 13.5664 7.28908 13.2959 6.49711 12.7857C6.63186 12.8022 6.77028 12.8086 6.91053 12.8086C7.72269 12.8086 8.46978 12.5327 9.06286 12.067C8.70144 12.0599 8.35123 11.9403 8.06105 11.7247C7.77087 11.5091 7.55517 11.2084 7.44403 10.8644C7.70359 10.9137 7.97097 10.9034 8.22594 10.8341C7.83366 10.7548 7.48088 10.5422 7.22743 10.2325C6.97398 9.92272 6.83546 9.53485 6.83536 9.13462V9.11353C7.06911 9.24278 7.33678 9.32162 7.62094 9.33078C7.25318 9.08597 6.99283 8.7099 6.89315 8.27949C6.79347 7.84908 6.86199 7.39685 7.0847 7.01528C7.52007 7.55062 8.06302 7.98855 8.67838 8.30073C9.29375 8.61292 9.96782 8.79239 10.6569 8.82753C10.5693 8.45565 10.607 8.06524 10.7642 7.717C10.9214 7.36875 11.1892 7.08219 11.526 6.90186C11.8628 6.72153 12.2498 6.65753 12.6268 6.71982C13.0037 6.7821 13.3495 6.96718 13.6104 7.24628C13.9984 7.16954 14.3703 7.02725 14.7104 6.82553C14.5812 7.22716 14.3105 7.56819 13.9487 7.78528C14.2923 7.7439 14.6278 7.65152 14.9442 7.5112C14.7118 7.85944 14.4191 8.16335 14.0798 8.40862Z" fill="white"/>
      </svg>
    </SvgIcon>
  )
}