import { Container, Grid, Link, Typography, Divider, Hidden, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import React from 'react';
import { CommonStyles } from '../../theme/CommonStyles';
import DlabIcon from '../icon/DLabIcon';
import { DlabColor } from '../../theme/DlabColor';
import AppStoreIcon from '../../image/btn_appstore.png';
import GooglePlayStoreIcon from '../../image/btn_googleplay.png';
import { useWidth } from '../home/WidthComponent';
import YoutubeIcon from "../icon/YoutubeIcon";
import TwitterIcon from "../icon/TwitterIcon";

const useStyles = makeStyles(theme => ({
  root: {
    //height:110,
    backgroundColor: DlabColor.DARK.BK_03,
    paddingLeft:0,
    paddingRight:0,
    maxWidth:"100%",
    [theme.breakpoints.only('xs')]: {
      height:310,
    },
  },
  main: {
    height:50,
    //backgroundColor:'blue',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft:7, 
    paddingRight:7,
    [theme.breakpoints.only('xs')]: {
      height:'auto',
      display:'flex',
      flexDirection:'row',
    },
  },
  left: {
    display:'flex',
    height:'100%',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor:'green',
    [theme.breakpoints.only('xs')]: {
      height:'auto',
    }
  },
  right: {
    display:'flex',
    height:'100%',
    flexDirection:'row',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    //backgroundColor:'pink',
    [theme.breakpoints.only('xs')]: {
      flexDirection:'column',
    },
  },
  rightTextDiv:{
    display:'flex',
    flexDirection:'column',
    height: 50,
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      height: 100,
    }
  },
  rightLinkDiv:{
    display:'flex',
    height:'100%',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      height: 50,
    }
  },
  iconButton:{
    color:DlabColor.DARK.TEXT_WT,
    "&:hover": {
      backgroundColor: "inherit"
    }
  },
  under:{
    height:30,
    flex:1,
    background: DlabColor.DARK.BK_03,
    alignContent:'center',
    alignItems:'center',
    justifyContent:'center',
  },
  copyright: {
    fontFamily: "Noto Sans JP",
    fontSize: 10,
    color:DlabColor.DARK.TEXT_WT,
  },
  description: {
    fontFamily: "Noto Sans JP",
    fontSize: 10,
    color: DlabColor.DARK.TEXT_WT,
    [theme.breakpoints.down('md')]: {
      fontSize: 8,
      margin:0,
      WebkitTransform: 'scale(0.8)',
      WebKitTransformOrigin:'0 0',
    },
  },
  linkText: {
    fontFamily: "Noto Sans JP",
    fontSize:10,
    margin:8,
    color: DlabColor.DARK.TEXT_WT,

    [theme.breakpoints.down('md')]: {
      fontSize: 8,
      margin:0,
      WebkitTransform: 'scale(0.8)',
      WebKitTransformOrigin:'0 0',
    },
    [theme.breakpoints.only('xs')]: {
      margin:16
    }
  },
  link: {
    color: DlabColor.DARK.TEXT_WT
  },
  xsLinkDiv: {
    display:'flex',
    justifyContent:'space-between',
    [theme.breakpoints.only('xs')]: {
      height:100,
    }
  },
  row:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
  },
  column: {
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    alignContent:'center',
    justifyContent:'center',
  },
  appImage: {
    width: 95,
    height: 32,
    margin:5,
    [theme.breakpoints.down('md')]: {
      width: 66,
      height: 22,
      margin:0,
    },
    [theme.breakpoints.only('xs')]: {
      width: 73,
      height: 28,
      margin:5,
    },
  },
  rightDiv: {
    width:'100%', 
    justifyContent:'space-between', 
    [theme.breakpoints.up('md')]: {
      paddingLeft:8, 
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft:16, 
    },
    [theme.breakpoints.only('xs')]: {
      height:160,
      flexDirection:'column', 
    }
  },
  divider:{
    background:"#4F535C",
  },

}));


const Footer = () => {
  const classes = useStyles();
  const commons = CommonStyles();
  const isXs = 'xs' === useWidth();

  /**
   * xs~sm
   */
   const shortContents = () => (
    <div className={clsx(classes.row, classes.rightDiv)}>
      <div className={clsx(classes.row, classes.xsLinkDiv)}>
        <div className={classes.column}>
          <Link href="https://info.daigovideolab.jp/terms" target="_blank" className={classes.link}>
            <Typography className={classes.linkText}>
              利用規約
            </Typography>
          </Link>
          <Link href="https://info.daigovideolab.jp/privacy" target="_blank" className={classes.link}>
            <Typography className={classes.linkText}>
              プライバシーポリシー
            </Typography>
          </Link>
        </div>
        <div className={classes.column}>
          <Link href="https://info.daigovideolab.jp/qa" target="_blank" className={classes.link}>
            <Typography className={classes.linkText}>
              よくあるご質問
            </Typography>
          </Link>
          <Link href="https://info.daigovideolab.jp/qa#h.q6s30g34bwpu" target="_blank" className={classes.link}>
            <Typography className={classes.linkText}>
              お問い合わせ
            </Typography>
          </Link>
        </div>
      </div>
      <div className={classes.rightTextDiv}>
        <Typography className={classes.description}>
            iOS端末もしくはAndroid端末をご利用の方は、
        </Typography>
        <Typography className={classes.description}>
            各ストアに専用アプリがあります。
        </Typography>
        <Typography className={classes.description}>
            ダウンロードしてサービスをご利用ください。
        </Typography>
      </div>
    </div>
  )

  /**
   * md~xl
   */
   const middleContents = () => (
    <div className={clsx(classes.row, classes.rightDiv)}>
      <div className={clsx(classes.row)}>
        <Link href="https://info.daigovideolab.jp/terms" target="_blank" className={classes.link}>
          <Typography className={classes.linkText}>
            利用規約
          </Typography>
        </Link>
        <Link href="https://info.daigovideolab.jp/privacy" target="_blank" className={classes.link}>
          <Typography className={classes.linkText}>
            プライバシーポリシー
          </Typography>
        </Link>
        <Link href="https://info.daigovideolab.jp/qa" target="_blank" className={classes.link}>
          <Typography className={classes.linkText}>
            よくあるご質問
          </Typography>
        </Link>
        <Link href="https://info.daigovideolab.jp/qa#h.q6s30g34bwpu" target="_blank" className={classes.link}>
          <Typography className={classes.linkText}>
            お問い合わせ
          </Typography>
        </Link>
      </div>
      
      <div className={classes.rightTextDiv}>
        <Typography className={classes.description}>
            iOS端末もしくはAndroid端末をご利用の方は、
        </Typography>
        <Typography className={classes.description}>
            各ストアに専用アプリがあります。
        </Typography>
        <Typography className={classes.description}>
            ダウンロードしてサービスをご利用ください。
        </Typography>
      </div>
    </div>
  )

  /**
   * lg
   */
   const largeContents = () => (
    <div className={clsx(classes.row, classes.rightDiv)}>
      <div className={classes.row}>
        <Link href="https://info.daigovideolab.jp/terms" target="_blank" className={classes.link}>
          <Typography className={classes.linkText}>
            利用規約
          </Typography>
        </Link>
        <Link href="https://info.daigovideolab.jp/privacy" target="_blank" className={classes.link}>
          <Typography className={classes.linkText}>
            プライバシーポリシー
          </Typography>
        </Link>
        <Link href="https://info.daigovideolab.jp/qa" target="_blank" className={classes.link}>
          <Typography className={classes.linkText}>
            よくあるご質問
          </Typography>
        </Link>
        <Link href="https://info.daigovideolab.jp/qa#h.q6s30g34bwpu" target="_blank" className={classes.link}>
          <Typography className={classes.linkText}>
            お問い合わせ先
          </Typography>
        </Link>
      </div>
      <div className={classes.rightTextDiv}>
        <Typography className={classes.description}>
            iOS端末もしくはAndroid端末をご利用の方は、
        </Typography>
        <Typography className={classes.description}>
            各ストアに専用アプリがあります。ダウンロードしてサービスをご利用ください。
        </Typography>  
      </div>
    </div>
   )

  return (
    <Container component="main" maxWidth="xl" className={classes.root}>
      <Divider variant='fullWidth' className={classes.divider} />

      <Grid container spacing={0} className={classes.main}>
        <Grid item xs={isXs ? 12 : 1} className={classes.left}>
          <Link to={"/"}>
            <DlabIcon style={{width:73,height:28}}/>
          </Link>
        </Grid>
        <Grid item xs={isXs ? 12 : 11} className={classes.right}>
          {/** XS以上レイアウト */}
          <Hidden xlUp smDown>
            {middleContents()}
          </Hidden>
          <Hidden mdUp>
            {shortContents()}
          </Hidden>
          <Hidden lgDown>
            {largeContents()}
          </Hidden>
          
          <div className={classes.rightLinkDiv}>
            <Link
              href="itms-apps://itunes.apple.com/jp/app/id1465859054?mt=8"
              target="_blank"
            >
              <img
                src={AppStoreIcon}
                alt='iOS'
                className={classes.appImage}
              />
            </Link>
            <Link
              href="https://play.google.com/store/apps/details?id=com.keigo.matsumaru.daigoVideoApp"
              target="_blank"
            >
              <img
                src={GooglePlayStoreIcon}
                alt='Android'
                className={classes.appImage}
              />
            </Link>
            
          </div>
        </Grid>
      </Grid>

      <Divider variant='fullWidth' className={classes.divider} />

      <Grid container spacing={0} className={classes.under}>
        <IconButton href="https://twitter.com/Mentalist_DaiGo" target="_blank" className={classes.iconButton}>
          <TwitterIcon viewBox="0 0 21 20" />
        </IconButton>
        <IconButton href="https://www.youtube.com/channel/UCFdBehO71GQaIom4WfVeGSw" target="_blank" className={classes.iconButton}>
          <YoutubeIcon />
        </IconButton>
      </Grid>

      <Divider variant='fullWidth' className={classes.divider} />

      <Grid container spacing={0} className={clsx(classes.under)} >
        <Typography className={classes.copyright} >
          Copyright &copy; D-lab All Rights Reserved.
        </Typography>
      </Grid>

    </Container>
  )
}

export default Footer;