import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography } from '@material-ui/core';

export const useWidth = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

/**
 * 現在のwidthサイズを表示するコンポーネント
 * @returns 
 */
const WidthComponent = () => {
  const width = useWidth();
  return <Typography align="center">{`width: ${width}`}</Typography>;
};

export default WidthComponent;