import React from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Typography, Hidden } from "@material-ui/core";
import DlabScrollBar from "../common/DlabScrollBar";
import Footer from "../footer/Footer";
import { CommonStyles } from "../../theme/CommonStyles";
import { DlabColor } from "../../theme/DlabColor";
import DlabIcon from "../icon/DLabIcon";
import maintenanceCatLarge from "../../image/maintenance_cat_large.png";
import maintenanceCatSmall from "../../image/maintenance_cat_small.png";

const useStyles = makeStyles((theme) => ({
  root: {
    background: DlabColor.DARK.BK_03,
    minHeight: "calc(100vh - 113px)",
    [theme.breakpoints.down("xs")]:{
      minHeight: "calc(100vh - 310px)",
    }
  },
  header: {
    width: "100%",
    position: "fixed",
    paddingTop: 16,
    paddingLeft: 16,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingLeft: 0,
    },
  },
  main: {
    maxWidth: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    fontFamily: "Noto Sans JP",
    '.& ::-webkit-scrollbar': {
      display:"none"
    },
  },
  catImg: {
    paddingTop: 80,
    paddingBottom: 13,
    width: "100%",
  },
  maintenanceText: {
    fontSize: "20px !important",
    fontWeight: "700 !important",
    lineHeight: "20px !important",
    paddingTop: 13,
    paddingBottom: 13,
  },
  maintenanceDateArea: {
    background: "#787C84",
    borderRadius: 4,
    width: "100%",
    textAlign: "center",
    paddingTop: 26,
    paddingBottom: 26,
    marginTop: 13,
    marginBottom: 13,
  },
  padding: {
    paddingTop: 13,
    paddingBottom: 13,
  },
}));

const CustomTypography = withStyles((theme) => ({
  root: {
    color: "#F9FAFC",
    fontSize: 18,
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
    fontFamily: [
      "Noto Sans JP",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
}))(Typography);

/**
 * @description メンテナンス時刻を記載する
 * @param {string} startTime - メンテナンス開始時刻を記載
 * @param {string} endTime - メンテナンス終了時刻を記載
 */
const startTime = "20xx年12月31日 12:00";
const endTime = "20xx年 1月1日 12:00";

const Maintenance = () => {
  const classes = useStyles();
  const commons = CommonStyles();

  return (
    <>
      <div className={clsx(classes.root)}>
        <div className={clsx(commons.appBar, classes.header)}>
          <DlabIcon />
        </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            className={commons.contentsMaxWidth}
          >
            <div className={classes.main}>
              <Hidden xsDown>
                <img
                  src={maintenanceCatLarge}
                  alt="maintenanceCat"
                  className={clsx(classes.catImg)}
                />
              </Hidden>
              <Hidden smUp>
                <img
                  src={maintenanceCatSmall}
                  alt="maintenanceCat"
                  className={clsx(classes.catImg)}
                />
              </Hidden>

              <div>
                <CustomTypography
                  className={clsx(classes.maintenanceText, classes.padding)}
                >
                  ただいまメンテナンス中です
                </CustomTypography>
              </div>

              <div className={classes.maintenanceDateArea}>
                <Hidden xsDown>
                  <CustomTypography>
                    メンテナンス日時
                    <br />
                    {`${startTime} ～ ${endTime}`}
                  </CustomTypography>
                </Hidden>

                <Hidden smUp>
                  <CustomTypography>
                    メンテナンス日時
                    <br />
                    {`${startTime}`}
                    <br />
                    {` ～ ${endTime}`}
                  </CustomTypography>
                </Hidden>
              </div>

              <Hidden xsDown>
                <CustomTypography
                  className={classes.padding}
                  style={{ paddingBottom: 26 }}
                >
                  ご利用の皆様には、ご迷惑をおかけし、 申し訳ございません。
                  <br />
                  メンテナンス終了まで しばらくお待ちください。
                </CustomTypography>
              </Hidden>

              <Hidden smUp>
                <CustomTypography
                  className={classes.padding}
                  style={{ paddingBottom: 26 }}
                >
                  ご利用の皆様には、ご迷惑をおかけし、
                  <br />
                  申し訳ございません。
                  <br />
                  メンテナンス終了まで
                  <br />
                  しばらくお待ちください。
                </CustomTypography>
              </Hidden>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Maintenance;
