import Maintenance from "./components/maintenance/Maintenance";
import DlabScrollBar from "./components/common/DlabScrollBar";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <>
      <Maintenance />
      
      
    </>
  );
}

export default App;