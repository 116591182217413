/**
 * Dラボカラー定数クラス
 * @descripion Dラボ内で使用するカラー定数
 */

export const DlabColor = {
  DARK: {
    // 背景色設定
    BG_COLOR_MAIN : '#191F29',
    BG_COLOR_SUB  : '#35383F',
    // テキストカラー設定
    TEXT_WT       : '#F9FAFC',
    TEXT_WT_HOVER : '#F9FAFC',
    TEXT_GR       : '#4F535C',
    TEXT_LISTGR   : '#787C84',
    TEXT_LIGHTGR  : '#BBBBBB',
    TEXT_BR       : '#3FC6FF',
    TEXT_YW       : '#FFFFBB',
    TEXT_BL       : '#3FC6FF',
    // ボタンカラー設定
    BTN_LIGHTBL   : '#3FC6FF',
    BTN_BL        : '#08AEF4',
    BTN_DARKBL    : '#008CDB',
    BTN_LIGHTBK   : '#4F535C',
    BTN_LIGHTGR   : '#4F535C',
    BTN_BK        : '#35383F',
    BTN_DARKBK    : '#26282D',
    BTN_WT_HOVER  : '#EEF0F2',
    BTN_GRAY      : '#888888',
    // フォームカラー
    FORM_FILL     : '#080A0D',
    // ダイアログカラー
    DIALOG        : '#35383F',
    LIGHT_DIALOG  : '#4F535C',

    // 黒
    BK_01         : '#080A0D',
    BK_02         : '#26282D',
    BK_03         : '#35383F',
    BK_04         : '#3D4047',
    BK_05         : '#4F535C',
    BK_06         : '#646871',
    BK_07         : '#787C84',
    BK_08         : '#BBBBBB',
    // ネイビー
    NAVY          : '#191F29',
    // 白
    WT            : '#F9FAFC',
    WT_DARK       : '#EEF0F2',
    // 黄
    YW_01         : '#FFFFBB',
    // グレー
    GRAY_01      : '#888888',
    GRAY_02      : '#A5A5A5',

    // 透過
    TRANSPARENT  : 'transparent',
    
    // リストカラー
    LIST_HOVER   : '#3D4047',
    ERROR        : '#FA5367',
  },
  LIGHT: {}
}